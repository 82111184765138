import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`About Me`}</h2>
    <p>{`Hi, I am a software engineer and creative developer with a passion for graphics and generative art. I have worked on various GUI frameworks, OpenGL, GLSL, Canvas etc on both Android and the Web.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      